/**
 * ## Mini Slice
 * 
 * @category Slice
 * @module Mini Slice
 */
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit';
import { IMiniPathConfigEntry } from "../types/types";
import miniPathConfig from "../config/mini.json";
import { ActivityType } from "../components/ActivityUI";
import { CharacterType, SceneType, SubjectType } from "../containers/PathTitle";
interface IMiniState {
    newScaleRatio: number;
    screenWidth: number;
    screenHeight: number;
    contentWidth: number;
    contentHeight: number;
    pathBaseWidth: number;
    activityWidth: number;
    strokeWidth: number;

    videoSrcWidth: number;
    videoSrcHeight: number;

    activityType: ActivityType;
    skin: IMiniPathConfigEntry
    shouldGameButtonsSlide: boolean;
    areGameButtonsVisible: boolean;
    areGameButtonsCollapsed: boolean;
    doesNeedRendering: boolean;
    restartDialogOpen: boolean;
    isPathComplete: boolean;
    isFirstActivityInSession: boolean | undefined;
    isRefreshing: boolean;
    
    debugSubject: string;
    debugScene: string;
    debugCharacter: string;
}

interface IScreenPosition {
    newScaleRatio: number
    screenWidth: number,
    screenHeight: number,
    contentWidth: number;
    contentHeight: number;
    pathBaseWidth: number,
    activityWidth: number,
    strokeWidth: number
}
interface IVideoSrcSize {
    videoSrcWidth: number,
    videoSrcHeight: number
}

const initialState: IMiniState = {
    newScaleRatio: 1,
    screenWidth: 0,
    screenHeight: 0,
    contentWidth: 0,
    contentHeight: 0,
    pathBaseWidth: 0,
    activityWidth: 0,
    strokeWidth: 0,

    videoSrcWidth: 1280,
    videoSrcHeight: 720,

    activityType: ActivityType.None,
    skin: miniPathConfig.skins.style[SceneType.Younger][CharacterType.Charlie],
    areGameButtonsVisible: true,
    areGameButtonsCollapsed: false,
    shouldGameButtonsSlide: true,
    doesNeedRendering: true,
    restartDialogOpen: false,
    isPathComplete: false,
    isFirstActivityInSession: undefined,
    isRefreshing: false,

    debugSubject: SubjectType.Debug,
    debugScene: SceneType.Debug,
    debugCharacter: CharacterType.Debug
}

const miniSlice = createSlice({
	name: "mini",
	initialState,
	reducers: {
        setScreenSize: (state, action: PayloadAction<IScreenPosition>) => {
            state.newScaleRatio = action.payload.newScaleRatio;
            state.screenWidth = action.payload.screenWidth;
            state.screenHeight = action.payload.screenHeight;
            state.contentWidth = action.payload.contentWidth;
            state.contentHeight = action.payload.contentHeight;
            state.pathBaseWidth = action.payload.pathBaseWidth;
            state.activityWidth = action.payload.activityWidth;
            state.strokeWidth = action.payload.strokeWidth;
            
        },
        setVideoSrcSize: (state, action: PayloadAction<IVideoSrcSize>) => {
            state.videoSrcWidth = action.payload.videoSrcWidth;
            state.videoSrcHeight = action.payload.videoSrcHeight;
        },
        setActivityType: (state, action: PayloadAction<ActivityType>) => {
            state.activityType = action.payload
        },
        showGameButtons: (state) => {
            state.areGameButtonsVisible = true;
        },
        hideGameButtons: (state) => {
            state.areGameButtonsVisible = false;
        },
        setShouldGameButtonsSlide: (state, action: PayloadAction<boolean>) => {
            state.shouldGameButtonsSlide = action.payload;
        },
        setAreGameButtonsCollapsed: (state, action: PayloadAction<boolean> ) => {
            state.areGameButtonsCollapsed = action.payload;
        },
        setSkin: (state, action: PayloadAction<IMiniPathConfigEntry>) => {
            state.skin = action.payload;
        },
        setDoesNeedRendering: (state, action: PayloadAction<boolean>) => {
            state.doesNeedRendering = action.payload
        },
        setRestartDialogOpen: (state, action: PayloadAction<boolean>) => {
            state.restartDialogOpen = action.payload
        },
        setIsPathComplete: (state, action: PayloadAction<boolean>) => {
            state.isPathComplete = action.payload
        },
        setIsFirstActivityInSession: (state, action: PayloadAction<boolean>) => {
            state.isFirstActivityInSession = action.payload
        },
        setIsRefreshing: (state, action: PayloadAction<boolean>) => {
            state.isRefreshing = action.payload;
        },

        setDebugSubject: (state, action: PayloadAction<string>) => {
            state.debugSubject = action.payload
        },
        setDebugScene: (state, action: PayloadAction<string>) => {
            state.debugScene = action.payload
        },
        setDebugCharacter: (state, action: PayloadAction<string>) => {
            state.debugCharacter = action.payload
        }
	}
});

export const { 
    setScreenSize,
    setVideoSrcSize,
    setActivityType,
    showGameButtons,
    hideGameButtons,
    setShouldGameButtonsSlide,
    setAreGameButtonsCollapsed,
    setSkin,
    setDoesNeedRendering,
    setRestartDialogOpen,
    setIsPathComplete,
    setIsFirstActivityInSession,
    setIsRefreshing,

    setDebugSubject,
    setDebugScene,
    setDebugCharacter
} = miniSlice.actions;

export default miniSlice.reducer;
