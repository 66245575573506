/**
 * ## Anywhere Teacher Activity Wrapper
 * 
 * Provides the wrapper for activities and the ochestration to retrieve and load  
 * all of the dependent libraries that the activity relies on.
 * 
 * **Phase 1**: Call out to existing Drupal 7 architecture to retrieve the list of libraries for  
 * a given activity (activityId) via fetch.
 * 
 * **Phase 2**: upon receipt of a successful retrieval add all of the libraries scripts to the  
 * DOM and attach load listeners to each one.
 * 
 * **Phase 3**: Upon each load event determine what library has finished and based on that process  
 * special handling and uplift of DOM globals from the parentFrame to the IFrame.
 * 
 * **Phase 4**: When all libraries are loaded then start (if using the TS Framework) the activity, if the JS framework  
 * then start is upon library load, and for video and songs load the VideoSongPlayer  
 * 
 * @category Container
 * @module Activity Wrapper
 */
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../hooks/hooks";

import {
    incrementLoadedCount,
    setScriptCount,
    setJSON,
    setIsJavascriptFramework,
    setIsFirstGameLoad,
    setIsLoadingScripts,
    updatePathData,
    updatePathDataProgress
} from "../../slices/activitySlice";
import { setIsButtonBlocked } from "../../slices/buttonSlice";
import ProgressLoading from "../../components/ProgressLoading";
import VideoSongPlayer from "../../components/VideoSongPlayer";
import { overrideSZButtons, overrideReporting, overrideLeaderboardInit, createMutationObserver, setBackgroundColor, overrideOrientationOverlay, overrideProgressSave, overrideVideoPlayer } from "./utils/activityUtils";
import { setActivityType, setAreGameButtonsCollapsed, setDoesNeedRendering, setShouldGameButtonsSlide, showGameButtons, setIsFirstActivityInSession } from "../../slices/miniSlice";
import { mapUrl } from "../../lib/urlTools";
import { sendMessage } from "../../lib/iFrameCommunications";
import { IActivityProps } from "../../types/types";

import {  } from "../../routes/Mini";
import { ActivityType } from "../../components/ActivityUI";

declare const SZ: any;
declare const window: any; 
declare let Drupal: any;

const Activity = (props: IActivityProps) => {
	const {
        isLoading,
        isLoaded,
        areAllScriptsLoaded,
        isLoadingScripts,
        activityId,
        isFirstGameLoad,
        isFinalRewardTime,
        isDemoTime
    } = useAppSelector((state) => state.activity);
	let activityJSON = useAppSelector((state) => state.activity.json);
	const parentFrame = useAppSelector((state) => state.activity.parent);
	const isPortrait = useAppSelector((state) => state.landscape.isPortrait);
    const isFirstActivityInSession = useAppSelector((state) => state.mini.isFirstActivityInSession);
    const isRefreshing = useAppSelector((state) => state.mini.isRefreshing);
    let isJavascriptFramework = useAppSelector((state) => state.activity.isJavascriptFramework);

	const dispatch = useAppDispatch();
    
    useEffect(() => {
        // TODO: Has to be a better way to reset the block padding - can't happen during the original build up or it blows up everything  
        // TODO: useEffect fails without significant timeout
        setTimeout(() => {
            sendMessage("CHANGE BLOCK");
        }, 5000);
    });
    /**
     * ### Script Load Listener
     * 
     * @param event                 payload from the script load event
     */
	const scriptListener = (event: any) => {
        /**
         * ! Phaser should be the first library loaded for all activities, other scripts have dependencies
         * ! on Phaser object exisiting on the DOM, for example, the typescript framework - exceept for global
         * ! dependencies like jQuery
         */   
        if (event.currentTarget.src.indexOf("1.10/jquery.min.js") !== -1) {
            /**
			 * + Set szEnableDebugLogging for the javascript framework  
             * + Make sure Leaderboard has viable JWT  
             * + Initialize other properties that are required as new activities load  
			 */

            var hostName = (window.location) ? window.location.hostname.toLowerCase() : "";
            const isLocalhost = hostName.indexOf("localhost") > -1;

            window.Drupal = {
                settings: {
                    szLoginFormIsLoaded: false,
                    szFiltersTimer: undefined,
                    sz_leaderboard: {},
                    sz_profiles: {
                        szPhaserProfile: parentFrame.settings.sz_profiles.szPhaserProfile
                    },
                    szTrackProgress: true,
                    sz_reporting: {
                        szEnableDebugLogging: false
                    },
                    anywhere_teacher: {
                        jwt: activityJSON.data.jwt
                    },
                    sz_profile_rewards: {
                        nid: activityId
                    },
                    szProgress: (isLocalhost) ? undefined: activityJSON.data.progress,
                    szLearningPathId: Number(parentFrame.settings.szLearningPathId),
                    szLearningPathActive: 1,
                    szLearningPractice: 0,
                },
                behaviors: {
                    szAnywhereTeacherVideos: {}
                }
            };
            /**
			 * remove SZ Typescript Framework from previous activity
			 */
            window.SZ = undefined;

        } else if (
            event.currentTarget.src.indexOf("sz-framework") !== -1 
            || event.currentTarget.src.indexOf("sz-globals-") !== -1
            || event.currentTarget.src.indexOf("_videos") !== -1
        ) {
			/**
			 * Early: Lift up the environment settings from the parent frame to the mini path iframe
             * These need to be done after the TS or JS Framework loads and before other scripts
			 */
            Drupal.settings.szProfileRewards = {};
			Drupal.settings.szProfileRewards.subscriber = parentFrame.settings.szProfileRewards.subscriber;
			Drupal.settings.sz_profiles.szProfileRewardsTotal = parentFrame.settings.sz_profiles.szProfileRewardsTotal;
            overrideReporting();
		}
        /**
         * if we are loading the JS Framework, set the state variable to indicate, so we can
         * determine what needs to be done to start an activity
         * 
         * JS Framework does not have a **SZActivity.startUp method** (like TS Framework) but instantiates on script loading
         */
        if (event.currentTarget.src.indexOf("sz-framework") !== -1) {
            dispatch(setIsJavascriptFramework(false));
            isJavascriptFramework = false;

            overrideLeaderboardInit();
        }
        
        if (event.currentTarget.src.indexOf("dynamic_content") !== -1) {
            Drupal.behaviors.atDynamicContent.attach();
        }

        if (event.currentTarget.src.indexOf("anywhere_teacher.js") !== -1) {
            overrideOrientationOverlay();

            Drupal.settings.anywhere_teacher.szKeepScreenOn = parentFrame.settings.anywhere_teacher.szKeepScreenOn;
            Drupal.settings.anywhere_teacher.szPhaserCDN = parentFrame.settings.anywhere_teacher.szPhaserCDN;

            Drupal.behaviors.szAnywhereTeacherTheme.attach();
        }

        if (event.currentTarget.src.indexOf("sz_reporting") !== -1) {
            overrideReporting();
        }

		dispatch(incrementLoadedCount());
	}

    /**
     * ## Add Script Tag to the DOM
     * 
     * @param url                       URL of script to add
     * @param allowListener             **true** attach listener to script load event, **false** no listener
     */
	const addScript = (url: string, allowListener: boolean = true) => {
		const script = document.createElement("script");
		script.src =  url;
		script.async = false;
        if (allowListener) {
            script.addEventListener("load", scriptListener)
        }
		document.getElementById("mini-scripts")?.appendChild(script);
	}
    
    // Remove the leaderboard if it is still present from previous activity
    const removeLeaderboard = () => {
        var leaderboardModal = document.getElementById("sz-high-score-modal");
        if (leaderboardModal) {
            leaderboardModal.remove();  // Removes the element from the DOM
        }

        var leaderboardBackdrop = document.querySelector(".modal-backdrop.fade.in");
        if (leaderboardBackdrop && leaderboardBackdrop.parentNode) {
            leaderboardBackdrop.parentNode.removeChild(leaderboardBackdrop);
        }
    }

	let params = useParams();

    if (isRefreshing) {
        return (
            <ProgressLoading />
        )
    } else if ((activityId > 0 && !isFinalRewardTime && !isDemoTime) && !isPortrait) {
		if (!isLoadingScripts && areAllScriptsLoaded) {
            if (isFirstGameLoad) {
                /**
                 * Complete: Lift up the environment settings from the parent frame to the mini path iframe
                 */
                Drupal.settings.szActivityConfig = activityJSON.data.config ? activityJSON.data.config[0] : "";
                Drupal.settings.anywhere_teacher.jwt = activityJSON.data.jwt;
                Drupal.settings.anywhere_teacher.uid = parentFrame.settings.anywhere_teacher.uid;
                Drupal.settings.sz_reporting.szReportingToken = activityJSON.data.jwt;
                Drupal.settings.sz_leaderboard.jwt = activityJSON.data.jwt;
                Drupal.settings.anywhere_teacher.szNid = activityId;
                Drupal.settings.anywhere_teacher.szPathId = params.pathId;
                Drupal.settings.szLearningPathReward = true;

			    window.szLogger.log("Child Environment: Complete", Drupal.settings);
            }
            /**
             * ## Choose StartUp Method for the New Activity
             */
            const startUp = () => {
                // Increase button sizes for small screens
                if (activityJSON.data.node.type === ActivityType.FlashCardsPhaser) {
                    if(window.arrSets.length < 4 && window.pageOrientation === "portrait" && (window.innerWidth < 800 || window.innerHeight < 800)){
                        window.szGame.Game.prototype.getGeneralButtonScale = function() {                            
                            var ratio = ( window.szGame.game.width / 1024) * 0.9;
                            return {
                                x: Math.max(ratio, 2),
                                y: Math.max(ratio, 2)
                            }
                        }
                    }
                }

                /**
                 * **szActivty.startUp** is only supported in TS Framework and only the  
                 * first time the game is loaded to stop from trying to restart the game  
                 * during resizing   
                 */
                if (!isJavascriptFramework && isFirstGameLoad) {
                    const szActivity = new SZ.Activity();
                    szActivity.startUp();
                }
                /**
                 * ### Copy Background Color to IFrame Body Tag
                 * 
                 * using the Phaser onStateChange event to wait until the boot and preloader have finsihed to
                 * manipulate the backgrounds
                 * 
                 * @param newStateName          new state name
                 * @param oldStateName          old state name
                 */
                const copyBackgroundToBody = (newStateName: string, oldStateName: string) => {
                    if (oldStateName === "Preloader") {
                        dispatch(setIsButtonBlocked(false));

                        requestAnimationFrame(() => {
                            setBackgroundColor(activityJSON);
                        });
                    } 
                }

                // AT2-344 - Give szGame a chance to be initialized?
                requestAnimationFrame(() => {
                    window.szGame.game.state.onStateChange.add(copyBackgroundToBody, null);

                    // Some activities (Books and Flash Cards) get resized on startUp using SZ.GameOptions.matchScreenSize
                    // Rerendering after the preloader helps rerender with the new activity dimensions
                    window.szGame.game.state.onStateChange.add((newStateName: string, oldStateName: string) => {
                        if (oldStateName === "Preloader") {
                            overrideProgressSave();

                            overrideVideoPlayer();

                            dispatch(setDoesNeedRendering(true));
                            dispatch(setAreGameButtonsCollapsed(false));
                            window.SZButtons.prototype.center(false, true);
                        }
                    }, null);
                });

                return null;
            }
            
            /**
             * ### Create a **Mutation Observer** 
             * 
             * detect when changes have been made to the media-wrapper and 
             * transfer those changes to the body
             */
            const createMutation = () => {
                setTimeout(() => { createMutationObserver(activityJSON) }, 1);
                return null;
            }

            /**
             * Override the SZButtons class to modify methods so that the positioning and docking of  
             * the HTML activity buttons can be modified to match the mini path layout 
             */
            overrideSZButtons(activityJSON.data.node.type);
            
            if (activityJSON.data.node.type === ActivityType.Video || activityJSON.data.node.type === ActivityType.Song) {
                // Phaser framework normally calls SZButton functions, songs and videos need to call them here
                dispatch(setAreGameButtonsCollapsed(false));
                dispatch(setShouldGameButtonsSlide(false));

                requestAnimationFrame(() => {
                    window.SZButtons.prototype.start();
                    window.SZButtons.prototype.center(true, true);
                });

                dispatch(setActivityType(activityJSON.data.node.type));
                dispatch(setIsFirstGameLoad(false));

                return (
                    <>
                        <VideoSongPlayer videos={activityJSON.data.videos} node={activityJSON.data.node} />
                        { createMutation() }
                        { setBackgroundColor(activityJSON) }
                    </>
                )
            } else {
                dispatch(setShouldGameButtonsSlide(true));

                if (isFirstGameLoad) {
                    dispatch(setActivityType(activityJSON.data.node.type));
                    dispatch(setIsFirstGameLoad(false));
                    dispatch(showGameButtons());
                }
                return(
                    <>
                        { startUp() }
                        { createMutation() }
                    </>
                )
            }
		} else if (isLoaded && !isLoading && !isLoadingScripts && !areAllScriptsLoaded && !isRefreshing) {
            dispatch(setIsLoadingScripts(true));
            const uid = parentFrame.settings.anywhere_teacher?.uid ? parentFrame.settings.anywhere_teacher.uid : 0;
            const pid = parentFrame.settings.sz_profiles?.szPhaserProfile?.active?.pid ? parentFrame.settings.sz_profiles.szPhaserProfile.active.pid : 0;
			window.szLogger.log("%c Fetching...", "background-color: pink; color: black", uid + " | " + pid);

            let scriptsPath = mapUrl() + "/adventure/get/activity/" + activityId + "/" + params.pathId + "/" + uid + "/" + pid;

            removeLeaderboard();

            if (typeof isFirstActivityInSession === 'undefined') {
                dispatch(setIsFirstActivityInSession(true));
            } else { 
                dispatch(setIsFirstActivityInSession(false));
            }

            /**
             * asynchronously load all scripts required by new activity
             */
			fetch (scriptsPath)
				.then(response => response.json())
				.then(json => {
					window.szLogger.log("%c Fetch Complete:", "background-color: pink; color: black", json);

					dispatch(setScriptCount(json.data.js.length));
                    /**
                     * move path progress data to parent
                     */
                    const pathProgressUpdate = json.data.pathData;
                    pathProgressUpdate.forEach((activity: any, index: number) => {
                        const pathData: any = {};
                        pathData.id = index + 1;
                        pathData.attempts = Number(activity.attempts);
                        pathData.confirmed = Number(activity.confirmed);
                        pathData.finished = Number(activity.finished);
                        pathData.successful_attempts = Number(activity.successful_attempts);
                        pathData.last_access = activity.last_access;
                        dispatch(updatePathData(pathData));
                    });

                    dispatch(setJSON(json));
                    activityJSON = json;
                    dispatch(updatePathDataProgress(json.data.allProgress ? json.data.allProgress : []));

                    json.data.js.forEach((url: string, index: number) => {
                        window.szLogger.log("AT Script: URL Added [" + index + "]", url);
                        addScript(url);
                    });

                    if (document.body) {
                        document.body.style.backgroundColor = "#000000";
                    }
				})
				.catch(error => {
					window.szLogger.log("Error: Fetching Error", error);
				});

			return (
				<ProgressLoading />
			)
		} else {
			return (
				<ProgressLoading />
			)
		}
	} 
    else {
		return (
			<ProgressLoading />
		)
	}
}

export default Activity;
